span.cm-underlined {
  text-decoration: underline;
}
span.cm-strikethrough {
  text-decoration: line-through;
}
span.cm-brace {
  color: #170;
  font-weight: bold;
}
span.cm-table {
  color: blue;
  font-weight: bold;
}
