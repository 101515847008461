.cm-tw-syntaxerror {
	color: #FFF;
	background-color: #900;
}

.cm-tw-deleted {
	text-decoration: line-through;
}

.cm-tw-header5 {
	font-weight: bold;
}
.cm-tw-listitem:first-child { /*Added first child to fix duplicate padding when highlighting*/
	padding-left: 10px;
}

.cm-tw-box {
	border-top-width: 0px !important;
	border-style: solid;
	border-width: 1px;
	border-color: inherit;
}

.cm-tw-underline {
	text-decoration: underline;
}